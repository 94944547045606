import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'

const mapStateToProps = state => ({
  authToken: state.user.authToken
})

function RequireAuth({
  children,
  authToken
}) {

  useEffect(() => {
    if (!authToken) {
      navigate('/')
    }
  }, [authToken])

  if (!authToken) {
    return null
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

const RequireAuthEnhanced = connect(mapStateToProps)(RequireAuth)

export const withRequireAuth = (WrappedComponent) => {
  return props => (
    <RequireAuthEnhanced>
      <WrappedComponent {...props} />
    </RequireAuthEnhanced>
  )
}
